import {
  Row,
  Col,
  Radio,
  Table,
  Image,
  Card,
  Button,
  Switch,
  Tooltip,
  Checkbox,
  Form,
  Input,
  Select,
  Tag,
  TimePicker,
  DatePicker,
} from "antd";
import "react-phone-input-2/lib/style.css";
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import { Link, useParams, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import Badge from "react-bootstrap/Badge";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import notfound from "../../assets/images/not_found.png";

import MultiImageUpload from "../../components/MultipleImageUpload";
import SingleImageUpload from "../../components/SingleImageUpload";
import lang from "../../helper/lang";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import useApi from "../../hooks/useApi";
import moment, { weekdays } from "moment";
import AddForm from "../Table/AddForm";

import MultipleImageUpload from "../../components/MultipleImageUpload";
const Option = Select;
const CheckboxGroup = Checkbox.Group;
const UserFrom = () => {
  const api = {
    // userList: apiPath.allUserList,
    editEvent: apiPath.editEvent,
    addEvent: apiPath.addEvent,
    venueList: apiPath.venueList,
  };

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];
  const [formmm] = Form.useForm();
  const params = useParams();
  const [searchText, setSearchText] = useState("");

  const { request } = useRequest();
  const [image, setImage] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [floorImage, setFloorImage] = useState();

  const [eventType, setEventType] = useState();
  const [entryType, setEntryType] = useState([]);
  const [countries, setCountries] = useState([]);
  const [recurring, setRecurring] = useState("");
  const [eventTable, setEventTable] = useState([]);
  const [recurringDate, setRecurringDate] = useState("");
  const [recurringDay, setRecurringDay] = useState("");

  const [story, setStory] = useState([]);
  const { getCountry } = useApi();
  const formData = formmm.getFieldsValue();

  // Now you can access formData to get guest list, ticket, table data
  const { guestList, ticket, table } = formData;
  const [menuImage, setMenuImage] = useState([]);
  const [venues, setVenues] = useState([]);
  const debouncedSearchText = useDebounce(searchText, 300);

  const getVenues = () => {
    request({
      url: apiPath.eventVenues,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
          console.log(data.data, 3222);
        }
        setVenues(data.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleVenueImage = (data) => {
    // setImage(data[0]?.url);
    setImage(data[0]?.url);
  };

  const handleMenuImage = (data) => {
    // setImage(data[0]?.url);
    setMenuImage(data[0]?.url);
  };

  const handleFloorImage = (data) => {
    setFloorImage(data.length > 0 ? data[0].url : "");
  };

  const handleVenueStory = (data) => {
    // setImage(data[0]?.url);
    setStory(data.map((story) => story.url));
  };

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.eventById + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        // setData(data.data);
        setStory(data.data.venueDetail.stories);
        setEntryType(data.data.venueDetail.entry_type);
        //  alert(moment(data.data.venueDetail.tableData.table_time).format("hh:mm"))
        formmm.setFieldsValue({
          ...data.data.venueDetail,
          ...data.data.venueDetail.tableData,
          ...data.data.venueDetail.guestListData,
          ...data.data.venueDetail.ticketData,
          country: data.data.venueDetail.country_id,
          venue_id: data.data.venueDetail.venue_id._id,
          start_time: moment(
            `${moment().format("YYYY-MM-DD")} ${
              data?.data?.venueDetail?.start_time
            }`,
            "YYYY-MM-DD HH:mm",
          ),
          end_time: moment(
            `${moment().format("YYYY-MM-DD")} ${
              data?.data?.venueDetail?.end_time
            }`,
            "YYYY-MM-DD HH:mm",
          ),

          guest_admin_comission:
            data.data.venueDetail.guestListData?.admin_commission,
          table_admin_comission:
            data.data.venueDetail.tableData?.admin_commission,
          ticket_admin_comission:
            data.data.venueDetail.ticketData?.admin_commission,
          table_time:
            data.data.venueDetail.tableData &&
            data.data.venueDetail.tableData?.table_time &&
            moment(data.data.venueDetail.tableData?.table_time),
          table_note: data.data.venueDetail.tableData?.note,
          guest_note: data.data.venueDetail.guestListData?.note,

          male_charges:
            data.data.venueDetail.guestListData?.coverCharges?.weekdays?.male,
          female_charges:
            data.data.venueDetail.guestListData?.coverCharges?.weekdays?.female,
          weekend_male_charges:
            data.data.venueDetail.guestListData?.coverCharges?.weekends?.male,
          weekend_female_charges:
            data.data.venueDetail.guestListData?.coverCharges?.weekends?.female,
          weekday_male_guestlist:
            data.data.venueDetail.guestListData?.coverCharges?.weekdays
              ?.male_guestlist,
          weekday_female_guestlist:
            data.data.venueDetail.guestListData?.coverCharges?.weekdays
              ?.female_guestlist,
          weekends_male_guestlist:
            data.data.venueDetail.guestListData?.coverCharges?.weekends
              ?.male_guestlist,
          weekends_female_guestlist:
            data.data.venueDetail.guestListData?.coverCharges?.weekends
              ?.female_guestlist,
          // max_capacity: data.data.venueDetail.guestListData?.max_capacity,
          signUp_before: moment(
            data.data.venueDetail.guestListData?.signUp_before,
          ),
          recurringDate: data.data.venueDetail.recurringDate
            ? moment(data.data.venueDetail.recurringDate)
            : "",
          // recurringDay: moment(
          //   data.data.venueDetail.recurringDay
          // ),
          arrive_before: moment(
            data.data.venueDetail.guestListData?.arrive_before,
          ),
        });
        setSelected({
          venue_id: data.data.venueDetail.venue_id._id,
          event_id: data.data.venueDetail._id,
        });
        setEventType(data.data.venueDetail.event_type);
        setImage(data.data.venueDetail.image);
        console.log(
          data.data.venueDetail.recurringDate,
          "date>>>>>>>>>>>>",
          data.data.venueDetail.recurringDay,
          "day>>>>>>>>>>>>",
        );
        // if (data.data.venueDetail.recurringDate) {
        //   setRecurring("Date")
        // } else if (data.data.venueDetail.recurringDay) {
        //   setRecurring("Day")
        setMenuImage(data.data.venueDetail.menu);
        // }
        if (data.data.venueDetail.recurringDate) {
          setRecurringDate(moment(data.data.venueDetail.recurringDate));
        } else if (data.data.venueDetail.recurringDay) {
          setRecurringDay(data.data.venueDetail.recurringDay);
        }

        setFloorImage(data.data.venueDetail.tableData?.table_layout);

        setSwitchState(data.data.venueDetail.is_featured);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchEventTable = (id) => {
    request({
      url: apiPath.eventTable + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        setEventTable(data.data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    getVenues();
    if (params.id) {
      setTimeout(() => {
        fetchData(params.id);
        fetchEventTable(params.id);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    // const {getCountry} = getCountry
    //  console.log(getCountry(),"getCountry")
    //  getCountry()
    getCountry({
      countryData: (data) => setCountries(data),
    });
  }, []);

  const tableColumns = [
    {
      title: `${lang("Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id }) => {
        return (
          <a className="cap avatar-text">{name ? name : "Not Available"}</a>
        );
      },
    },

    {
      title: `${lang("Capacity")}`,
      dataIndex: "capicity",
      key: "capicity",
      render: (_, { capicity }) => {
        return (
          <a className="cap avatar-text">
            {capicity ? capicity : "Not Available"}
          </a>
        );
      },
    },
    {
      title: `${lang("Min. Cost")}`,
      dataIndex: "min_cost",
      key: "min_cost",
      render: (_, { min_cost }) => {
        return (
          <a className="cap avatar-text">
            {min_cost ? min_cost : "Not Available"}
          </a>
        );
      },
    },
    //   {
    //     title: `${lang("Max. People")}`,
    //     dataIndex: "max_male",
    //     key: "max_male",
    //     render: (_, { max_male }) => {
    //     return (<a className="cap avatar-text">{max_male ? max_male : 'Not Available'}</a>);
    //     },
    // },
    {
      title: `${lang("Reservation Fee")}`,
      dataIndex: "reservation_fee",
      key: "reservation_fee",
      render: (_, { reservation_fee }) => {
        return (
          <a className="cap avatar-text">
            {reservation_fee ? reservation_fee : "Not Available"}
          </a>
        );
      },
    },
    {
      title: "Table Status",
      key: "table_status",
      dataIndex: "table_status",
      render: (_, { table_status, _id }) => {
        return <p>{table_status ? "Booked" : "Available"}</p>;
      },
    },

    {
      title: `${lang("Status")}`,
      key: "is_active",
      render: (_, { is_active }) => {
        return (
          <Tag color={is_active ? "green" : "red"}>
            {is_active ? "Active" : "Inactive"}
          </Tag>
        );
      },
    },

    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
    },
  ];

  const onCreate = () => {
    // setLoading(true);
    const values = formmm.getFieldsValue();

    // Now you can access formData to get guest list, ticket, table data
    // const { guestList, ticket, table } = formData;
    const guestListData = {
      coverCharges: {
        weekdays: {
          male: values.male_charges,
          female: values.female_charges,
          male_guestlist: values.weekday_male_guestlist,
          female_guestlist: values.weekday_female_guestlist,
        },
        weekends: {
          male: values.weekend_male_charges,
          female: values.weekend_female_charges,
          male_guestlist: values.weekends_male_guestlist,
          female_guestlist: values.weekends_female_guestlist,
        },
      },
      max_capacity: values.max_capacity,
      signUp_before: values.signUp_before,
      arrive_before: values.arrive_before,
      new_male_admin_comission: values.male_admin_commission,
      new_female_admin_comission: values.female_admin_commission,

      note: values.guest_note,
    };
    const tableData = {
      table_time: values.table_time,
      no_of_table: values.no_of_table,
      no_of_people: values.no_of_people,
      advanced_deposit: values.advanced_deposit,
      note: values.table_note,
      table_layout: floorImage,
      new_comission: values.table_admin_comission,
    };

    const ticketData = {
      affiliate_link: values.affiliate_link,
      promo_codes: values.promo_codes,
      new_comission: values.ticket_admin_comission,
    };
    let stories = [];
    values?.stories?.map((story) => stories.push(story.url));

    let payload = {
      ...values,
      image: image?.length ? image : "",
      menu: menuImage?.length ? menuImage : "",
      event_type: eventType,
      entry_type: entryType,
      guestListData: guestListData,
      tableData: tableData,
      ticketData: ticketData,
      stories: stories,
      recurringDay: recurringDay,
      recurringDate: recurringDate,
    };

    // Clear one of the properties based on the condition
    if (recurringDay) {
      payload.recurringDay = recurringDay;
      payload.recurringDate = "";
    } else if (recurringDate) {
      payload.recurringDate = recurringDate;
      payload.recurringDay = "";
    }
    request({
      url: `${params.id ? api.editEvent + "/" + params.id : api.addEvent}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          navigate("/event");
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const options = [
    { label: "Table Reservation", value: "Table" },
    { label: "By Tickets", value: "Tickets" },
    { label: "Guest List", value: "Guest List" },
  ];
  const days = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thrusday", value: "Thrusday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ];

  const [switchState, setSwitchState] = useState(false);

  const handleChange = (checked) => {
    console.log(`Switch is ${checked ? "on" : "off"}`);
    setSwitchState(checked);
  };

  const changeEventType = (value) => {
    setEventType(value);
    if (value == "Special") {
      setRecurring("");
      // setRecurringDate("");
      setRecurringDay("");
    }
  };

  return (
    <>
      <Card>
        <Form
          id="create"
          form={formmm}
          initialValues={{}}
          onFinish={onCreate}
          layout="vertical"
        >
          <Row gutter={[24, 16]} style={{ padding: "20px" }}>
            <Col span={24} lg={12} sm={12}>
              <Form.Item
                className="mb-1 form-mb-0"
                label="Event Name"
                name="name"
                normalize={(value) => value.trimStart()}
                rules={[
                  { required: true, message: "Please Enter the event name!" },
                  {
                    max: 200,
                    message:
                      "Event Name should not contain more then 200 characters!",
                  },
                  {
                    min: 2,
                    message: "Event Name should contain at least 2 characters!",
                  },
                ]}
              >
                <Input autoComplete="off" placeholder="Enter Event Name" />
              </Form.Item>
            </Col>

            <Col span={24} lg={12} sm={12}>
              <div className="add-vanue-ses">
                <Form.Item
                  className="mb-1 form-mb-0"
                  label="Venue Name"
                  name="venue_id"
                  normalize={(value) => value.trimStart()}
                  rules={[{ required: true, message: "Please select venue!" }]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    showSearch // Enable search functionality
                    filterOption={(inputValue, option) =>
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                    placeholder="Select Venue"
                    // value={formmm.getFieldValue("venue_id")}
                    // onChange={(value) => formmm.setFieldValue("venue_id", value)}
                    options={venues.map((v) => ({
                      label: v?.name,
                      value: v?._id,
                    }))}
                  />
                </Form.Item>
                <Link
                  className="ant-btn ant-btn-primary"
                  to={`/venue-manager/add`}
                >
                  Add Venue
                </Link>
              </div>
            </Col>

            <Col span={24} lg={12} sm={12}>
              <Form.Item
                className="form-mb-0"
                label="Start Time"
                name="start_time"
                rules={[
                  {
                    required: true,
                    message: "Please select event time!",
                  },
                ]}
              >
                <TimePicker
                  className="date-els"
                  format="HH:mm"
                  minuteStep={30}
                  use12Hours={true}
                />
              </Form.Item>
            </Col>

            <Col span={24} lg={12} sm={12}>
              <Form.Item
                className="form-mb-0"
                label="End Time"
                name="end_time"
                rules={[
                  {
                    required: true,
                    message: "Please select event time!",
                  },
                ]}
              >
                <TimePicker
                  className="date-els"
                  format="HH:mm"
                  minuteStep={30}
                  use12Hours={true}
                />
              </Form.Item>
            </Col>

            <Col span={24} lg={12} sm={12}>
              <Form.Item
                className="mb-1  form-mb-0"
                label="Event Description"
                name="description"
                normalize={(value) => value.trimStart()}
                rules={[
                  {
                    required: true,
                    message: "Please Enter the event description!",
                  },
                  {
                    max: 200,
                    message:
                      "Event Description should not contain more then 200 characters!",
                  },
                  {
                    min: 2,
                    message:
                      "Event Description should contain at least 2 characters!",
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  placeholder="Enter Event Description"
                />
              </Form.Item>
            </Col>

            <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
              <Form.Item
                label="Event Type"
                name="event_type"
                className="form-mb-0"
                // rules={[
                //   { required: true, message: "Please Select Event Type!" },
                // ]}
              >
                {console.log(eventType, 51444)}
                <Radio.Group
                  value={eventType}
                  onChange={(e) => changeEventType(e.target.value)}
                >
                  <Tooltip title="Events that happen on a regular schedule, like weekly or monthly.">
                    <Radio value={"Recurring"}>Recurring</Radio>
                  </Tooltip>
                  <Tooltip title="One-time events that do not repeat.">
                    <Radio value={"Special"}>Special</Radio>
                  </Tooltip>
                </Radio.Group>
              </Form.Item>
              <div className="d-flex gap-3">
                {/* {eventType == "Recurring" ?
                  <Form.Item className="mb-0 w-100">
                    {console.log(recurring, "reverse>>>>>>>")}
                    <Radio.Group onChange={(e) => setRecurring(e.target.value)} defaultValue={recurring}>
                      <Tooltip title="Events that happen on a regular schedule, like weekly or monthly.">
                        <Radio value={"Day"}>Day</Radio>
                      </Tooltip>
                      <Tooltip title="One-time events that do not repeat.">
                        <Radio value={"Date"}>Date</Radio>
                      </Tooltip>
                    </Radio.Group>

                  </Form.Item> : <DatePicker

                    format="YYYY-MM-DD"
                    style={{ width: '100%' }}
                    onChange={(value) => setRecurringDate(value)}
                    defaultValue={recurringDate}
                  />} */}
                {eventType == "Recurring" ? (
                  <Form.Item name={"recurringDay"} className="mb-0 w-100">
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select Day"
                      onChange={(value) => setRecurringDay(value)}
                      value={recurringDay}
                      options={days.map((v) => ({
                        label: v?.name,
                        value: v?.value,
                      }))}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item name={"recurringDate"} className="mb-0 w-100">
                    <DatePicker
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                      onChange={(value) => setRecurringDate(value)}
                      defaultValue={recurringDate}
                    />
                  </Form.Item>
                )}
              </div>
            </Col>

            <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
              <Form.Item
                className="form-mb-0"
                label="Entry Type"
                name="entry_type"
                rules={[
                  { required: true, message: "Please Select Entry Type!" },
                ]}
              >
                <CheckboxGroup
                  options={options}
                  onChange={(e) => setEntryType(e)}
                />
              </Form.Item>
            </Col>

            <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
              <Form.Item
                className="form-mb-0"
                label="DJ Name"
                name="dj_type"
                // rules={[
                //   { required: true, message: "Please Select Event Type!" },
                // ]}
              >
                <Input placeholder="Enter DJ Name" />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
              <Form.Item
                className="form-mb-0"
                label="Music Type"
                name="music_type"
                // rules={[
                //   { required: true, message: "Please Select Event Type!" },
                // ]}
              >
                <Input placeholder="Enter music Type" />
              </Form.Item>
            </Col>

            <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
              <Form.Item
                label="Featured"
                name="is_featured"
                className="form-mb-0"
              >
                <Switch
                  checked={switchState}
                  onChange={(checked) => setSwitchState(checked)}
                />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                className="form-mb-0"
                label={"Upload Event Image (Width : 1130 x Height : 640)"}
                name="image"
              >
                <SingleImageUpload
                  value={image}
                  fileType={FileType}
                  imageType={"venue"}
                  btnName={"Event Image"}
                  onChange={(data) => handleVenueImage(data)}
                />
              </Form.Item>

              {image && image.length > 0 && (
                <div className="mt-2">
                  <Image
                    width={100}
                    src={
                      image && image.length > 0 && image !== ""
                        ? image
                        : notfound
                    }
                  ></Image>
                </div>
              )}
            </Col>
            <Col>
              <Form.Item className="form-mb-0">
                <SingleImageUpload
                  value={menuImage}
                  fileType={FileType}
                  imageType={"menue"}
                  btnName={"Menu"}
                  onChange={(data) => handleMenuImage(data)}
                />
              </Form.Item>

              {menuImage && menuImage.length > 0 && (
                <div className="mt-2">
                  <Image
                    width={100}
                    src={
                      menuImage && menuImage.length > 0 && menuImage !== ""
                        ? menuImage
                        : notfound
                    }
                  ></Image>
                </div>
              )}
            </Col>

            {!params.id ? (
              <Col span={24} md={12}>
                <Form.Item
                  className="form-mb-0"
                  label={"Upload Event Story (Width : 1130 x Height : 640)"}
                  name="stories"
                >
                  <MultipleImageUpload
                    value={story}
                    fileType={FileType}
                    imageType={"story"}
                    btnName={"Event Stories"}
                    onChange={(data) => handleVenueStory(data)}
                  />
                </Form.Item>

                {/* {story && story.length > 0 &&
                story.map((sto, index) => (
                  <div key={index} className="mt-4">
                    {console.log(sto, 99999)}
                    <img
                      width={100}
                      src={sto && sto.length > 0 && sto !== "" ? sto : notfound}
                      alt={`Image ${index}`}
                    />
                  </div>
                ))
              } */}
              </Col>
            ) : (
              ""
            )}
            {entryType.includes("Guest List") ? (
              <Row gutter={[24, 16]} className="border-tikts">
                <Col span={24}>
                  <h3>Guest List Fields</h3>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="WeekDays Male Charges(weekdays without guestlist in $)"
                    name="male_charges"
                    rules={[
                      {
                        required: true,
                        message: "Please add male charges without guestlist!",
                      },
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Weekdays Female Charges(weekdays without guestlist in $)"
                    name="female_charges"
                    rules={[
                      {
                        required: true,
                        message: "Please add female charges without guestlist!",
                      },
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Weekends Male Charges(weekends without guestlist in $)"
                    name="weekend_male_charges"
                    rules={[
                      {
                        required: true,
                        message: "Please add male charges without guestlist!",
                      },
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Weekends Female Charges(weekends without guestlist in $)"
                    name="weekend_female_charges"
                    rules={[
                      {
                        required: true,
                        message: "Please add female charges without guestlist!",
                      },
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Weekday Male guestlist charges(in $)"
                    name="weekday_male_guestlist"
                    rules={[
                      {
                        required: true,
                        message: "Please add week days guestlist charges!",
                      },
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Weekday Female guestlist charges(in $)"
                    name="weekday_female_guestlist"
                    rules={[
                      {
                        required: true,
                        message: "Please add week days guestlist charges!",
                      },
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Weekend male guestlist charges(for guestlist in $)"
                    name="weekends_male_guestlist"
                    rules={[
                      {
                        required: true,
                        message: "Please add weekend charges for guestlist!",
                      },
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Weekend female guestlist charges(for guestlist in $)"
                    name="weekends_female_guestlist"
                    rules={[
                      {
                        required: true,
                        message: "Please add weekend charges for guestlist!",
                      },
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>

                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Max. Capacity"
                    name="max_capacity"
                    rules={[
                      {
                        required: true,
                        message: "Please enter max no. of capacity!",
                      },
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      placeholder="Enter number........"
                    />
                  </Form.Item>
                </Col>

                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Sign-up before"
                    name="signUp_before"
                    rules={[
                      { required: true, message: "Please add sign-up time!" },
                    ]}
                  >
                    <TimePicker
                      className="date-els"
                      format="HH:mm"
                      minuteStep={30}
                      use12Hours={true}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Arrive before"
                    name="arrive_before"
                    rules={[
                      { required: true, message: "Please add arriving time!" },
                    ]}
                  >
                    <TimePicker
                      className="date-els"
                      format="HH:mm"
                      minuteStep={30}
                      use12Hours={true}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Male Admin Commision"
                    name="male_admin_commission"
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Female Admin Commision"
                    name="female_admin_commission"
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Note(max. 100 limit)"
                    name="guest_note"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || value.length <= 100) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Note must be 100 characters or less!"),
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      maxLength={100}
                      autoComplete="off"
                      placeholder="Enter note"
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              ""
            )}
            {entryType.includes("Tickets") ? (
              <Row gutter={[24, 16]} className="border-tikts">
                <Col span={24}>
                  <h3>Tickets Fields</h3>
                </Col>

                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    label="Affiliate Link"
                    name="affiliate_link"
                    className="form-mb-0"
                    rules={[
                      { required: true, message: "Please add affiliate link!" },
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter link" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Promo Codes"
                    name="promo_codes"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || value.length <= 10) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Promo codes must be 10 characters or less!",
                            ),
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      maxLength={10}
                      autoComplete="off"
                      placeholder="Enter promo codes........"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Admin Commision(in %)"
                    name="ticket_admin_comission"
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              ""
            )}
            {entryType.includes("Table") ? (
              <Row gutter={[24, 16]} className="border-tikts">
                <Col span={24}>
                  <h3>Table Fields</h3>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Time for table reservation"
                    name="table_time"
                    rules={[{ required: true, message: "Please add time!" }]}
                  >
                    <TimePicker
                      className="date-els"
                      minuteStep={30}
                      use12Hours={true}
                      format="HH:mm"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="No. of Table"
                    name="no_of_table"
                    rules={[{ required: true, message: "Please add number!" }]}
                  >
                    <Input autoComplete="off" placeholder="Enter number" />
                  </Form.Item>
                </Col>
                {/* <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    label="No.of People(per table)"
                    name="no_of_people"
                    rules={[
                      { required: true, message: "Please add number!" },
                    ]}
                  >
                    <Input autoComplete="off" placeholder="Enter number" />
                  </Form.Item>
                </Col> */}
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Note(max. 100 limit)"
                    name="table_note"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || value.length <= 100) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Not must be less than 100 characters!"),
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      placeholder="Enter note........"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label="Admin Commision(in %)"
                    name="table_admin_comission"
                  >
                    <Input autoComplete="off" placeholder="Enter charges" />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                  <Form.Item
                    className="form-mb-0"
                    label={"Upload floor  Image (Width : 1130 x Height : 640)"}
                    name="floor_img"
                    rules={
                      [
                        // {
                        //   validator: (_, value) => {
                        //     if (value !== undefined && value?.length > 0) {
                        //       return Promise.resolve();
                        //     }
                        //     return Promise.reject(new Error('Venue Image is required'));
                        //   },
                        // }
                      ]
                    }
                  >
                    <MultiImageUpload
                      value={floorImage}
                      fileType={FileType}
                      imageType={"tableFloor"}
                      btnName={"Floor Image"}
                      onChange={handleFloorImage}
                    />
                  </Form.Item>

                  {floorImage && floorImage.length > 0 && (
                    <div className="mt-2">
                      <Image
                        width={100}
                        src={
                          floorImage &&
                          floorImage.length > 0 &&
                          floorImage !== ""
                            ? floorImage
                            : notfound
                        }
                      ></Image>
                      {/* <Button title="Delete" onClick={() => setFloorImage([])}>
                <i className="fas fa-trash"></i>
              </Button> */}
                    </div>
                  )}
                </Col>

                {params.id ? (
                  <Col span={24}>
                    {" "}
                    <Button
                      className="mb-2"
                      onClick={(e) => {
                        setVisible(true);
                        setSearchText("");
                      }}
                    >
                      Add Table
                    </Button>
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={tableColumns}
                        dataSource={eventTable}
                        pagination={{
                          defaultPageSize: 10,
                          responsive: true,
                          showSizeChanger: true,
                          pageSizeOptions: ["10", "20", "30", "50"],
                        }}
                        className="ant-border-space"
                      />
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            ) : (
              ""
            )}
            <div className="view-inner-cls float-right">
              <Link className="ant-btn ant-btn-primary" to={`/event`}>
                {lang("Back")}
              </Link>
            </div>
            <div className="view-inner-cls float-right">
              <Button htmlType="submit" className="ant-btn ant-btn-primary">
                {lang("Save")}
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
      {visible && (
        <AddForm
          type={"Table"}
          path={api.addEdit}
          sectionName={"Table"}
          show={visible}
          hide={() => {
            setVisible(false);
            fetchEventTable(params.id);
          }}
          selected={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
    </>
    // </Modal >
  );
};

export default UserFrom;

import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Input,
  Tag,
  DatePicker,
  Tooltip,
  Avatar,
  Image,
} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import moment from "moment";
import { useNavigate } from "react-router";
import ShowTotal from "../../components/ShowTotal";
import apiPath from "../../constants/apiPath";
import AddForm from "./AddForm";
import ImportForm from "../../components/ImportForm";
import ConfirmationBox from "../../components/ConfirmationBox";
import ConfirmationDelete from "../../components/ConfirmationDelete";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";
import lang from "../../helper/lang";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {
  const sectionName = lang("Photos");
  const routeName = "photo";

  const api = {
    type: apiPath.changePhotoType,
    status: apiPath.photosStatus,
    delete: apiPath.deletePhoto,
    addEvent: apiPath.addEditEvent,
    list: apiPath.photosList,
    approvetSory: apiPath.approvetSory,
    importFile: apiPath.importEvent,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const { confirmDelete } = ConfirmationDelete();
  // const { confirmDelete } = ConfirmationDelete()
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const columns = [
    {
      title: `${lang("Image")}`,
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => {
        const parts = image?.split(".");
        const extension = parts?.length > 0 ? parts[parts.length - 1] : "";
        return extension == "mp4" ? (
          <Avatar className="image-index-radius" src={apiPath.assetURL + image}>
            MP4
          </Avatar>
        ) : (
          <Image
            className="image-index-radius"
            src={apiPath.assetURL + image}
          />
        );
      },
    },
    {
      title: `${lang("Venue Name")}`,
      dataIndex: "venue_id",
      key: "venue_id",
      render: (_, { venue_id }) => {
        return (
          <a className="cap avatar-text">
            {venue_id ? venue_id?.name : "Not Available"}
          </a>
        );
      },
      sorter: (a, b) => {
        let nameA = a.venue_id?.name?.toLowerCase();
        let nameB = b.venue_id?.name?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      },
    },
    {
      title: `${lang("Event Name")}`,
      dataIndex: "event_id",
      key: "event_id",
      render: (_, { event_id }) => {
        return (
          <a className="cap avatar-text">
            {event_id ? event_id?.name : "Not Available"}
          </a>
        );
      },
      sorter: (a, b) => {
        let nameA = a.event_id?.name?.toLowerCase();
        let nameB = b.event_id?.name?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      },
    },
    {
      title: `${lang("Access Type")}`,
      dataIndex: "image_type",
      key: "image_type",
      render: (_, { image_type, _id }) => {
        let color = image_type == "story" ? "orange" : "blue";
        return (
          <a>
            <Tag color={color} key={image_type}>
              {image_type == "story"
                ? image_type.charAt(0).toUpperCase() + image_type.slice(1)
                : "Gallery"}
            </Tag>
          </a>
        );
      },
    },
    {
      title: `${lang("Status")}`,
      key: "is_active",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? "green" : "red";
        const type = "is_active";
        return (
          <a>
            <Tag
              onClick={(e) =>
                showConfirm({
                  record: _id,
                  path: api.status,
                  onLoading: () => setLoading(true),
                  onSuccess: () => setRefresh((prev) => !prev),
                })
              }
              color={color}
              key={is_active}
            >
              {is_active ? "Active" : "Inactive"}
            </Tag>
          </a>
        );
      },
    },
    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
    },
    {
      title: `${lang("Action")}`,
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              title={`${lang("Update")} ` + sectionName}
              color={"purple"}
              key={"update" + routeName}
            >
              <Button
                title="Edit"
                onClick={() => {
                  setSelected(record);
                  setVisible(true);
                }}
              >
                <i className="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>

            {record.image_type == "story" ? (
              record.image_status == false ? (
                <>
                  <Tooltip>
                    <Button
                      onClick={(e) =>
                        showConfirm({
                          record: record._id,
                          path: api.approvetSory,
                          onLoading: () => setLoading(true),
                          onSuccess: () => setRefresh((prev) => !prev),
                        })
                      }
                    >
                      <CheckOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip>
                    <Button
                      onClick={() =>
                        confirmDelete({
                          record: record._id,
                          path: api.delete,
                          onLoading: () => setLoading(true),
                          onSuccess: () => setRefresh((prev) => !prev),
                        })
                      }
                    >
                      <CloseOutlined />
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <Tooltip
                  title={`${lang("Delete")} ` + sectionName}
                  color={"purple"}
                  key={"Delete" + routeName}
                >
                  <Button
                    title="Delete"
                    onClick={() =>
                      confirmDelete({
                        record: record._id,
                        path: api.delete,
                        onLoading: () => setLoading(true),
                        onSuccess: () => setRefresh((prev) => !prev),
                      })
                    }
                  >
                    <i className="fas fa-trash"></i>
                  </Button>{" "}
                </Tooltip>
              )
            ) : (
              <Tooltip
                title={`${lang("Delete")} ` + sectionName}
                color={"purple"}
                key={"delete" + routeName}
              >
                <Button
                  title="Delete"
                  onClick={() =>
                    confirmDelete({
                      record: record._id,
                      path: api.delete,
                      onLoading: () => setLoading(true),
                      onSuccess: () => setRefresh((prev) => !prev),
                    })
                  }
                >
                  <i className="fas fa-trash"></i>
                </Button>{" "}
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
  }, [refresh, debouncedSearchText, startDate, endDate]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;
    const filterVerify = filters ? filters.is_verify : null;

    request({
      url:
        api.list +
        `?verify=${filterVerify ? filterVerify.join(",") : ""}&status=${
          filterActive ? filterActive.join(",") : ""
        }&page=${pagination ? pagination.current : 1}&limit=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}&start_date=${
          startDate ? startDate : ""
        }&end_date=${endDate ? endDate : ""}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setList(data?.data?.docs);
        setPagination((prev) => ({
          current: pagination.current,
          total: data.data.totalDocs,
        }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 });
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
      setPagination({ current: 1 });
    } else {
      setStartDate();
      setEndDate();
      setPagination({ current: 1 });
    }
  };

  // const excelData = list.map(row => ({
  //   "Name": row.user_id.name ? row.user_id.name : '-',
  //   "Event Name": row.event_id.name ? row.event_id.name : '-',
  //   "Total Guests": row.total_guest ? row.total_guest : '-',
  //   "CheckedIn User": row.checked_user ? row.checked_user : '-',
  //   "Registered On": moment(row.created_at).format("DD-MM-YYYY")
  // }));

  return (
    <>
      <div className="tabled customerMain">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + ` ${lang("Manager")}`}
              extra={
                <>
                  <div className="searchOuter">
                    <Search
                      className="mainSearch"
                      size="large"
                      allowClear
                      onChange={onSearch}
                      value={searchText}
                      onPressEnter={onSearch}
                      placeholder={`${lang("Search")} ${lang("By")} ${lang(
                        "Venue",
                      )} ${lang("Name")}`}
                    />
                    <RangePicker
                      disabledDate={(current) => current.isAfter(Date.now())}
                      onChange={handleChangeDate}
                    />
                  </div>
                  <div className="button_group">
                    <Button
                      onClick={(e) => {
                        setVisible(true);
                        setSearchText("");
                      }}
                    >
                      {lang("Add")} {sectionName}
                    </Button>
                    {/* <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;{lang("Export")}</Button> */}
                  </div>
                </>
              }
            >
              <h4 className="text-right mb-1">
                {pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}
              </h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{
                    defaultPageSize: 10,
                    responsive: true,
                    total: pagination.total,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30", "50"],
                  }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && (
        <AddForm
          type={sectionName}
          path={api.addEdit}
          sectionName={sectionName}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {importVisible && (
        <ImportForm
          path={api.importFile}
          sectionName={sectionName}
          show={importVisible}
          hide={() => setImportVisible(false)}
          existingData={list}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
    </>
  );
}
export default Index;

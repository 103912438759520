let URL;
let appMode = process.env.REACT_APP_ENV;
// let ASSET_URL = "https://sugamaya.s3.amazonaws.com/";
let ASSET_URL = "";

if (appMode === "development") {
  URL = "http://153.92.4.13:6215/api/";
} else {
  // URL = "http://153.92.4.13:6215/api/";
  URL = "https://backend.iconnect.party/api/";
  // URL = "http://localhost:6215/api/";
}

// 153.92.4.13:6555

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "vendor/dashboard/list",

  addVenue: "vendor/venue/add",
  editVenue: "vendor/venue/edit",
  venueById: "vendor/venue",
  venueEvents: "vendor/venue/events/",

  venueList: "vendor/venue/list",
  venuestatus: "vendor/venue/active-deactive",

  addEvent: "vendor/event/add",
  editEvent: "vendor/event/edit",
  eventList: "vendor/event/list",
  eventListing: "vendor/event/event-listing",

  eventTable: "vendor/event/tableList",
  ticketGuests: "vendor/event/ticketGuests",
  eventVenues: "vendor/event/venueListing",

  eventGuest: "vendor/event/guestList",

  eventById: "vendor/event/byid",
  eventByVenueId: "vendor/event/venue-id",
  eventStatus: "vendor/event/active-deactive",

  deleteEvent: "vendor/event",

  addEditTable: "vendor/table/add-edit",
  editReserve: "vendor/table/reserve/edit",

  tableList: "vendor/table/list",
  manageTableStatus: "vendor/table/manage_table",
  tableStatus: "vendor/table/active-deactive",
  deleteTable: "vendor/table",
  reserveTableList: "vendor/table/reservations",
  requestsTableList: "vendor/table/tableRequests",
  reservationStatus: "vendor/table/change_status",
  exportTable: "vendor/table/export/data",

  ticketSummaryList: "vendor/ticket/summary/list",

  addPhotos: "/vendor/gallary/add",
  editPhotos: "/vendor/gallary/edit",

  approvetSory: "/vendor/gallary/approve",

  changePhotoType: "/vendor/gallary/type",
  photosList: "/vendor/gallary/list",
  photosStatus: "vendor/gallary/active-deactive",
  deletePhoto: "vendor/gallary",

  guestList: "vendor/guest-list/list",
  addEditGuest: "vendor/guest-list/add-edit",
  deleteGuest: "vendor/guest-list/delete",

  countryList: "common/country",

  listOnDemandOrder: "admin/order/on-demand/list",
  viewOnDemandOrder: "admin/order/on-demand/view",
  listSubscriptionOrder: "admin/order/subscription/list",
  viewSubscriptionOrder: "admin/order/subscription/view",
  changeOrderStatus: "admin/order/change-status",

  listTransaction: "admin/transaction/list",
  viewTransaction: "admin/transaction/view",

  // Auth API
  logout: "admin/auth/logout",
  login: "admin/auth/login",

  profile: "admin/auth/get-profile",
  updateProfile: "admin/auth/update-profile",
  changePassword: "admin/auth/change-password",
  updateAppSetting: "admin/auth/update-app-setting",

  forgotPassword: "admin/auth/forgot-password",
  verifyOTP: "admin/auth/verify-otp",
  resetPassword: "admin/auth/reset-password",

  editSetting: "admin/setting/edit",
  viewSetting: "admin/setting/view",

  // SubAdmin APIs
  listSubAdmin: "admin/sub-admin/list",
  addEditSubAdmin: "admin/sub-admin/add-edit",
  statusSubAdmin: "admin/sub-admin/status",
  viewSubAdmin: "admin/sub-admin/view",
  getModule: "admin/sub-admin/module-list",
  addPermission: "admin/sub-admin/add-permission",

  // Subscription APIs
  listSubscription: "admin/subscription/list",
  addEditSubscription: "admin/subscription/add-edit",
  statusSubscription: "admin/subscription/status",
  viewSubscription: "admin/subscription/view",
  editSubscription: "admin/subscription/edit",

  // Transaction APIs
  listTransaction: "admin/transaction/list",
  viewTransaction: "admin/transaction/view",

  // Referral APIs
  listReferral: "admin/referral/list",
  viewReferral: "admin/referral/view",

  // Customer APIs
  listCustomer: "admin/customer/list",
  addEditCustomer: "admin/customer/add-edit",
  statusCustomer: "admin/customer/status",
  deleteCustomer: "admin/customer/delete",
  viewCustomer: "admin/customer/view",
  importCustomer: "admin/customer/import-file",

  // Driver APIs
  listDriver: "admin/driver/list",
  addEditDriver: "admin/driver/add-edit",
  statusDriver: "admin/driver/status",
  deleteDriver: "admin/driver/delete",
  viewDriver: "admin/driver/view",
  importDriver: "admin/driver/import-file",

  // Vendor APIs
  listVendor: "admin/vendor/list",
  addEditVendor: "admin/vendor/add-edit",
  statusVendor: "admin/vendor/status",
  statusVendorAll: "admin/vendor/status-all",
  deleteVendor: "admin/vendor/delete",
  viewVendor: "admin/vendor/view",
  importVendor: "admin/vendor/import-file",

  // Vendor Staff APIs
  listVendorStaff: "vendor/scanner-staff/list",
  addEditVendorStaff: "vendor/scanner-staff/add-edit",
  statusVendorStaff: "vendor/scanner-staff/status",
  deleteVendorStaff: "vendor/scanner-staff/delete",
  viewVendorStaff: "vendor/scanner-staff/view",
  importVendorStaff: "vendor/vendor-staff/import-file",

  // Discount APIs
  listDiscount: "admin/discount/list",
  addEditDiscount: "admin/discount/add-edit",
  statusDiscount: "admin/discount/status",
  viewDiscount: "admin/discount/view",

  // Start Car Routes APIs
  listCarMake: "admin/car/make",
  addEditCarMake: "admin/car/add-edit-make",
  statusCarMake: "admin/car/status-make",
  importCarMake: "admin/car/import-make",

  listCarModel: "admin/car/model",
  addEditCarModel: "admin/car/add-edit-model",
  statusCarModel: "admin/car/status-model",
  importCarModel: "admin/car/import-model",

  listCarType: "admin/car/type",
  addEditCarType: "admin/car/add-edit-type",
  importCarType: "admin/car/import-type",
  statusCarType: "admin/car/status-type",

  listCarVariant: "admin/car/variant",
  addEditCarVariant: "admin/car/add-edit-variant",
  statusCarVariant: "admin/car/status-variant",
  importCarVariant: "admin/car/import-variant",

  // Manage Delivery
  listDelivery: "admin/manage-delivery/list",
  statusDelivery: "admin/manage-delivery/status",
  addEditDelivery: "admin/manage-delivery/add-edit",
  viewDelivery: "admin/manage-delivery/view",

  // Opening Time
  listOpeningTime: "admin/opening-time/list",
  statusOpeningTime: "admin/opening-time/status",
  addEditOpeningTime: "admin/opening-time/add-edit",
  viewOpeningTime: "admin/opening-time/view",

  // End Car Routes APIs

  listCategory: "admin/category/list",
  addEditCategory: "admin/category/add-edit",
  statusCategory: "admin/category/status",
  importCategory: "admin/category/import-file",

  listSubCategory: "admin/sub-category/list",
  addEditSubCategory: "admin/sub-category/add-edit",
  statusSubCategory: "admin/sub-category/status",
  importSubCategory: "admin/sub-category/import-file",

  // MakeModel APIs
  listCar: "admin/car/list",
  addEditCar: "admin/car/add-edit",
  statusCar: "admin/car/status",
  viewCar: "admin/car/view",

  // Service APIs
  listService: "admin/service/list",
  addEditService: "admin/service/add-edit",
  statusService: "admin/service/status",
  viewService: "admin/service/view",
  importService: "admin/service/import-file",

  // EmailTemplate APIs
  listEmailTemplate: "admin/email-template/list",
  addEditEmailTemplate: "admin/email-template/add-edit",
  statusEmailTemplate: "admin/email-template/status",
  viewEmailTemplate: "admin/email-template/view",

  // Blog APIs
  listBlog: "admin/blog/list",
  addEditBlog: "admin/blog/add-edit",
  statusBlog: "admin/blog/status",
  viewBlog: "admin/blog/view",

  // Banner APIs
  listBanner: "admin/banner/list",
  addEditBanner: "admin/banner/add-edit",
  statusBanner: "admin/banner/status",
  viewBanner: "admin/banner/view",

  // Banner APIs
  listBrand: "admin/brand/list",
  addEditBrand: "admin/brand/add-edit",
  statusBrand: "admin/brand/status",
  viewBrand: "admin/brand/view",
  importBrand: "admin/brand/import-file",

  // Content APIs
  listContent: "admin/content/list",
  addEditContent: "admin/content/add-edit",
  statusContent: "admin/content/status",
  viewContent: "admin/content/view",

  // Product APIs
  listProduct: "admin/product/list",
  addEditProduct: "admin/product/add-edit",
  statusProduct: "admin/product/status",
  viewProduct: "admin/product/view",
  editProduct: "admin/product/edit",
  importProduct: "admin/product/import-file",
  categoryAttribute: "admin/product/get-attribute",
  addAttribute: "admin/product/add-attribute",
  viewProductDealer: "admin/product/view-dealer",

  // Attribute APIs
  attribute: "admin/attribute",
  listAttribute: "admin/attribute/list",
  addEditAttribute: "admin/attribute/add-edit",
  statusAttribute: "admin/attribute/status",
  viewAttribute: "admin/attribute/view",
  editAttribute: "admin/attribute/edit",

  recentNotifications: "admin/notification/get/recent", // last ten notifications
  activityNotificationList: "admin/notification/activity/get",
  readNotification: "admin/notification/activity/read",
  unreadNotification: "admin/notification/activity/unread",
  deleteActivityNotification: "admin/notification/activity/delete",

  // Common Routes
  allUserList: "common/users-list",
  // vendorList: "common/users-list/vendor",
  // driverList: "common/users-list/driver",
  // customerList: "common/users-list/customer",
  subscriptionList: "common/subscription",
  prodcutList: "common/prodcut",
  categoryList: "common/category",
  subCategoryList: "common/sub-category",
  productList: "common/product",
  getAppSetting: "common/app-setting",
  activity: "common/user-activity",
};

export default apiPath;
